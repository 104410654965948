import(/* webpackMode: "eager", webpackExports: ["Avatar","AvatarImage","AvatarFallback"] */ "/vercel/path0/components/ui/avatar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownMenu","DropdownMenuTrigger","DropdownMenuContent","DropdownMenuLabel","DropdownMenuSeparator","DropdownMenuItem"] */ "/vercel/path0/components/ui/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Sheet","SheetTrigger","SheetContent"] */ "/vercel/path0/components/ui/sheet.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/(public)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
